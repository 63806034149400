<template>
  <ion-page>
    <Header :title="$t('Challenges')" back-link-header="true" />

    <ion-content :fullscreen="true">
      <Grid
        :items="challenges"
        :size="12"
        :large-size="12"
      />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from '@ionic/vue'
import { notificationsOutline } from 'ionicons/icons'
import { ref } from 'vue'
import { getChallenges } from '@/api/api'
import { useStore } from '@/store'
import Header from '@/components/header/AppHeader.vue'
import Grid from '@/components/old/Grid.vue'
export default {
  name: 'ChallengeList',
  components: {
    IonContent,
    IonPage,
    Header,
    Grid,
  },
  setup() {
    const { state } = useStore()
    const challenges: any = ref(null)
    const apiCall = async (type = 'new') => {
      const chs = await getChallenges({ type })
      challenges.value = chs
    }
    apiCall()
    return {
      notificationsOutline,
      notifications: state.notifications,
      user: state.user,
      challenges,
      segmentChanged: (ev: CustomEvent) => {
        challenges.value = null
        apiCall(ev.detail.value)
      },
    }
  },
}
</script>

<style scoped></style>
